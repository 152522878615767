import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "custom-popups"
    }}>{`Custom Popups`}</h1>
    <p>{`Our Popups extension allows to display your custom popups on any page. Popup content can be added in a banner in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`.`}</p>
    <p>{`Check the detailed instruction here: `}<a parentName="p" {...{
        "href": "https://bc-addons-docs.papathemes.com/popups.html"
      }}>{`https://bc-addons-docs.papathemes.com/popups.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      